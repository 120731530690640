import { getCookie } from 'cookies-next';
import { ExperienceIds, StorageKeys } from '../../../utilities/constants/qubit';
import { handleCustomQubitEvent } from '../../../utilities/vendors';
import logger from '../../../utilities/logger';
import { getExperienceOptions } from '../../../utilities/ssr';
import { QubitCustomEvents } from '~/utilities/context/dynamic/ExperienceOptionsProvider';

export const handleHomePageVariations = async (country: string, isProduction: boolean) => {
  try {
    const cocoCookie = getCookie('coco') || '';
    const contextId = getCookie('_qubitTracker')?.toString() || '';
    const cocoMinimumConsent = cocoCookie?.toString().endsWith('111');
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isNewsletterLink = urlSearchParams.get('utm_source') === 'Newsletter';
    const isEmailLink = urlSearchParams.get('utm_medium') === 'email';
    const isPreview = urlSearchParams.get('preview') === 'true';
    const gender = urlSearchParams.get('gxt');
    const isTk37TriggerUrl = isNewsletterLink && isEmailLink && (gender === '0' || gender === '1');
    let variationIsControl = !cocoMinimumConsent;
    let variationId = '';
    let experienceId = '';

    if (!variationIsControl) {
      const storageKey = `${isTk37TriggerUrl ? StorageKeys.TK37 : StorageKeys.TK38}_${country}`;
      const { TK38_DEV, TK38_PRD, TK37_DEV, TK37_PRD } = ExperienceIds;
      const callback = sessionStorage.getItem(`${storageKey}_callback`);

      variationId = urlSearchParams.get('qeId') || sessionStorage.getItem(storageKey) || '';

      if (isTk37TriggerUrl) {
        experienceId = isProduction ? TK37_PRD : TK37_DEV;
      } else {
        experienceId = isProduction ? TK38_PRD : TK38_DEV;
      }

      if (isTk37TriggerUrl && variationId?.includes('tk37')) {
        variationIsControl = !variationId || variationId.includes('tk37_c');
        [experienceId] = variationId.split('_');

        document.dispatchEvent(
          new CustomEvent(QubitCustomEvents.TK37_EXPERIENCE_OPTIONS_RESPONSE, {
            detail: { variationId },
          })
        );

        if (callback && callback.includes('https://sse.qubit.com/v1/callback')) {
          fetch(callback, { method: 'POST' });
        }
      } else if (variationId?.includes('tk38')) {
        variationIsControl = variationId.includes('tk38_c');

        if (callback && callback.includes('https://sse.qubit.com/v1/callback')) {
          fetch(callback, { method: 'POST' });
        }
      } else {
        const {
          id = '',
          isControl = false,
          callback = '',
          payload = {},
        } = variationId !== 'null'
          ? (await getExperienceOptions(experienceId, contextId, isPreview)) || {}
          : {};

        const isIncludedCountry =
          !payload.includedCountries ||
          payload.includedCountries?.length === 0 ||
          payload.includedCountries?.includes(country.split('_').pop() ?? '');

        if (id && isIncludedCountry) {
          if (
            id.toString() === TK37_DEV ||
            id.toString() === TK37_PRD ||
            id.toString() === TK38_DEV ||
            id.toString() === TK38_PRD
          ) {
            variationIsControl = isControl ?? false;

            if (isTk37TriggerUrl) {
              variationId = isControl ? `${experienceId}_tk37_c` : `${experienceId}_tk37_v1`;
            } else {
              variationId = isControl ? `${experienceId}_tk38_c` : `${experienceId}_tk38_v1`;
            }

            sessionStorage.setItem(storageKey, variationId);
            document.dispatchEvent(
              new CustomEvent(QubitCustomEvents.TK37_EXPERIENCE_OPTIONS_RESPONSE, {
                detail: { variationId },
              })
            );

            if (callback && callback.includes('https://sse.qubit.com/v1/callback')) {
              sessionStorage.setItem(`${storageKey}_callback`, callback);
              fetch(callback, { method: 'POST' });
            }
          }
        } else {
          variationIsControl = true;
          sessionStorage.setItem(storageKey, 'null');
        }
      }
    }

    const qubitAssets = document.querySelectorAll('[data-qubit-id]');

    qubitAssets.forEach(asset => {
      const dataQubitIdValue = asset.getAttribute('data-qubit-id');
      const isControlAsset = dataQubitIdValue?.includes('control');
      let rowCategories;

      if (variationIsControl) {
        document.dispatchEvent(
          new CustomEvent(QubitCustomEvents.TK37_EXPERIENCE_OPTIONS_RESPONSE, {
            detail: { variationId },
          })
        );

        if (isControlAsset) {
          asset.classList.remove('is-hidden');
          asset.classList.remove('invisible');

          if (variationId.endsWith('tk38_c') || variationId.endsWith('tk37_c')) {
            handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, asset);
          }
        }

        if (
          dataQubitIdValue === 'categories-men' &&
          (variationId.endsWith('tk38_c') || variationId.endsWith('tk37_c'))
        ) {
          handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, asset);
        }

        if (variationId.endsWith('tk37_c')) {
          handleCustomQubitEvent('scrollDepthEvent', experienceId, variationIsControl);
        }
      }

      if (!variationIsControl) {
        if (isControlAsset) {
          asset.setAttribute('hidden', 'true');
        } else if (variationId.includes('tk38') && dataQubitIdValue === 'TK38/talent') {
          asset.removeAttribute('hidden');
          handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, asset);
        } else if (variationId.includes('tk37')) {
          if (gender === '0') {
            if (dataQubitIdValue === 'TK37/male') {
              asset.removeAttribute('hidden');
              handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, asset);
            }

            if (dataQubitIdValue === 'hp_categories') {
              rowCategories = asset.querySelector('[data-name="Men"]');
            }
          }

          if (gender === '1') {
            if (dataQubitIdValue === 'TK37/female') {
              asset.removeAttribute('hidden');
              handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, asset);
            }

            if (dataQubitIdValue === 'hp_categories') {
              rowCategories = asset.querySelector('[data-name="Women"]');
            }
          }

          handleCustomQubitEvent('ctaClickEvent', experienceId, variationIsControl, rowCategories);
          handleCustomQubitEvent('scrollDepthEvent', experienceId, variationIsControl);
        }
      }
    });
  } catch (error) {
    const controlAssets = document.querySelectorAll('[data-qubit-id="control"]');

    controlAssets.forEach(asset => {
      asset.classList.remove('is-hidden');
      asset.classList.remove('invisible');
    });

    logger.error(`A/B test error: ${(error as Error).message}`);
  }
};
