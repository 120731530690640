/* eslint-disable no-underscore-dangle */
import type { GetStaticPaths, GetStaticPathsResult, GetStaticProps, NextPage } from 'next';
import { memo, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { ClearPreviewModeCookiesButton } from '~/components/shared/cookie/ClearPreviewModeCookiesButton';
import { MasterLayout } from '~/components/shared/core/layouts/MasterLayout';
import { SearchContextProvider } from '~/utilities/context/dynamic/SearchContext';
import { HomepageMetaHead } from '~/components/shared/metaHead/HomepageMetaHead';
import { Placeholder } from '~/components/shared/placeholder/Placeholder';
import type { CommonPageProps } from '~/types/props';
import { showLayoutContentForHomepage } from '~/utilities/amplience';
import { PageTypes } from '~/utilities/constants';
import {
  HOMEPAGE_LAYOUT_SLOT_DELIVERY_KEY,
  MASTER_LAYOUT_SLOT_DELIVERY_KEY,
  QUICK_VIEW_OVERLAY_DELIVERY_KEY,
} from '~/utilities/constants/amplienceDeliveryKeys';
import { CookieProvider } from '~/utilities/context/dynamic/CookieContext';
import { NavActionContextProvider } from '~/utilities/context/dynamic/NavActionContext';
import { DataLayerProvider } from '~/utilities/context/static/DataLayerContext';
import { StaticContextProvider } from '~/utilities/context/static/StaticContext';
import type { SiteType } from '~/utilities/graphql/codegen';
import { parseLocale } from '~/utilities/parsers';
import { getAmplienceContent } from '~/utilities/ssr/cacheAmplienceContent';
import { filterCommonAmplienceContents, getCommonStaticProps } from '~/utilities/ssr/staticProps';
import type { MasterLayout as MasterLayoutSchema } from '~/amplienceTypes/schemas/imported/master-layout-schema';
import type { PreviewData } from '../../api/preview';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { handleHomePageVariations } from '~/components/homepage/variations/utilities';
import { getAmplienceHubName, getPpeDeliveryKeys } from '~/utilities/helpers';
import { getLocalesForSiteType } from '~/utilities/ssr/staticPaths';

const Homepage: NextPage<CommonPageProps> = ({
  genericLayoutSlotContent,
  masterLayoutSlotContent,
  leftNavigation,
  flyoutNavigation,
  outletFlyoutNavigation,
  inpageNavigation,
  configuration,
  socialMediaChannels,
  countryEn,
  quickViewContent,
  isPreviewMode,
  ppeContent,
}: CommonPageProps) => {
  const { country, isProduction } = useAppContext();

  useEffect(() => {
    if ('PerformanceObserver' in window) {
      new PerformanceObserver(entryList => {
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of entryList.getEntries()) {
          const lcpElement = (entry as { element: Element } & PerformanceEntry).element;

          lcpElement?.setAttribute('loading', 'eager');
        }
      }).observe({ type: 'largest-contentful-paint', buffered: true });
    }
  }, []);

  useEffect(() => {
    handleHomePageVariations(country, isProduction);
  }, [country, isProduction]);

  if (!masterLayoutSlotContent) {
    return <Placeholder />;
  }

  const main = genericLayoutSlotContent?.slotContent ? (
    showLayoutContentForHomepage(genericLayoutSlotContent?.slotContent)
  ) : (
    <Placeholder />
  );

  const metadataContent = genericLayoutSlotContent?.metaData;
  const uspListContent = genericLayoutSlotContent?.uspList;

  return (
    <>
      <div className="page--landingPage has-mouse">
        <StaticContextProvider
          pageType={PageTypes.HOME}
          configuration={configuration || {}}
          leftNavigation={leftNavigation || {}}
          flyoutNavigation={flyoutNavigation || {}}
          outletFlyoutNavigation={outletFlyoutNavigation || {}}
          inpageNavigation={inpageNavigation || {}}
          socialMediaChannels={socialMediaChannels || []}
          quickViewContent={quickViewContent}
          countryEn={countryEn || {}}
          accessibilityLabels={masterLayoutSlotContent?.slotContent?.accessibilityLabels?.content}
          formErrorLabels={masterLayoutSlotContent?.slotContent?.formErrorLabels?.content}
          notifications={masterLayoutSlotContent?.slotContent?.notifications?.content}
          ppeContent={ppeContent}
        >
          <NavActionContextProvider
            defaultValue={genericLayoutSlotContent?.pageProperties?.sidenavDefaultOpen}
          >
            <DataLayerProvider>
              <SearchContextProvider>
                <HomepageMetaHead metadataContent={metadataContent} />
                <CookieProvider>
                  {isPreviewMode && <ClearPreviewModeCookiesButton />}
                  <MasterLayout
                    pageProperties={genericLayoutSlotContent?.pageProperties}
                    main={main}
                    uspListContent={uspListContent}
                    pageType={PageTypes.HOME}
                    {...(masterLayoutSlotContent?.slotContent as MasterLayoutSchema)}
                  />
                </CookieProvider>
              </SearchContextProvider>
            </DataLayerProvider>
          </NavActionContextProvider>
        </StaticContextProvider>
      </div>
    </>
  );
};

export default memo(Homepage, isEqual);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const sitesToBuild = (process.env.SITE_TYPES_TO_BUILD || 'gstar').split(',');

  if (!locales) {
    throw new Error('locales needed');
  }

  const paths: GetStaticPathsResult['paths'] = (
    await Promise.all(
      sitesToBuild.map(async siteType => {
        const indexPaths: GetStaticPathsResult['paths'] = [];
        const insterectLocales =
          (await getLocalesForSiteType(siteType as SiteType, locales || [])) || [];

        insterectLocales.forEach((locale: string) => {
          indexPaths.push({ params: { subdomain: siteType }, locale });
        });

        return indexPaths;
      })
    )
  ).flat();

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  locale,
  preview,
  previewData,
  params: { ...params },
}) => {
  const logger = (await import('~/utilities/logger')).default;

  const { subdomain } = params;
  const siteType = subdomain as SiteType;

  const amplienceHubName = getAmplienceHubName(siteType as SiteType);

  if (!locale || locale === 'default') {
    logger.warn('[homepage] Locale not found');

    return {
      notFound: true,
    };
  }

  try {
    const commonProps = await getCommonStaticProps({
      siteType,
      locale,
      translationPath: '',
      pageType: PageTypes.HOME,
    });

    if (!commonProps.configuration) {
      logger.warn('Configuration data is empty from backend.');

      return {
        notFound: true,
      };
    }

    const { language, country } = parseLocale(locale);

    const homepageLayoutSlotDeliveryKey =
      commonProps.configuration.homepageLayoutSlotDeliveryKey || HOMEPAGE_LAYOUT_SLOT_DELIVERY_KEY;
    const masterLayoutSlotDeliveryKey =
      commonProps.configuration.masterLayoutSlotDeliveryKey || MASTER_LAYOUT_SLOT_DELIVERY_KEY;
    const quickViewDeliveryKey =
      commonProps.configuration.quickViewOverlayDeliveryKey || QUICK_VIEW_OVERLAY_DELIVERY_KEY;

    const amplienceContents = await getAmplienceContent(
      [
        { key: homepageLayoutSlotDeliveryKey },
        { key: masterLayoutSlotDeliveryKey },
        { key: quickViewDeliveryKey },
        ...getPpeDeliveryKeys(commonProps.configuration),
      ],
      `${language}-${country.toUpperCase()},${language},en`,
      amplienceHubName,
      previewData as PreviewData
    );

    return {
      props: {
        ...commonProps,
        ...filterCommonAmplienceContents(amplienceContents, PageTypes.HOME),
        isPreviewMode: !!preview,
        siteType,
      },
      revalidate: 60 * 8,
    };
  } catch (error) {
    logger.error(`Error caught for homepage: ${(error as Error).message}`);

    return {
      notFound: true,
    };
  }
};
